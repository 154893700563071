<template>
  <div>
    <base-list-view
      :dataSource="dataSource"
      :headers="headers"
      :loading="loading"
      @onRefresh="init"
      @onDelete="del"
      pageEditName="KnowledgeBaseCourseEdit"
      title="Учебные курсы"
      label-text-btn-create="Создать курс"
      label-text-search="Поиск курса"
      showCopy
      sort-by="Name"
      :showCompleted.sync="showCompleted"
      :notShowActions="['add']"
      :showFilters="true"
    >
      <template slot="item.Period" slot-scope="{ item }">
        <span
          :class="{
            'error--text':
              item.AllEmployeesCount !== item.DoneEmployeesCount &&
              moment(item.DateOut, 'DD.MM.YYYY') < moment(),
          }"
        >
          {{
            item.IsLimited
              ? PeriodFormat(item.Period.DateIn, item.Period.DateOut)
              : ""
          }}
        </span>
      </template>
      <template slot="item.Curators" slot-scope="{ item }">
        <div style="width: 150px">
          {{
            item.Curators
              ? item.Curators.map((e) =>
                  $options.filters.PersonShortName(e)
                ).join(", ")
              : ""
          }}
        </div>
      </template>
      <template slot="item.DoneEmployeesCount" slot-scope="{ item }">
        {{ item.DoneEmployeesCount }}
        из
        {{ item.AllEmployeesCount }}
        ({{ completionPercentage(item) }}%)
      </template>
    </base-list-view>
  </div>
</template>
<script>
import Vue from "vue";
import moment from "moment";
import KnowledgeBase from "@/services/KnowledgeBaseService";
import BaseListView from "../../layouts/BaseListView.vue";
import ListEditMixin from "@/mixins/ListEditMixin";
import { CourseStatus } from "@/data/enums";
export default {
  name: "knowledgebase-all",
  components: {
    BaseListView,
  },
  mixins: [ListEditMixin],
  data() {
    return {
      apiService: KnowledgeBase.course,
      headers: [
        { text: "Название курса", value: "Name", notGroup: true },
        {
          text: "Тематика / Вид учебного материала",
          value: "Subject",
          width: "1%",
          dataType: "object",
          displayText: (e) => e?.Name,
        },
        {
          text: "Период",
          value: "Period",
          width: "15%",
          align: "center",
          cellClass: "td-minimum td5",
          dataType: "Period",
        },
        {
          text: "Кураторы",
          value: "Curators",
          align: "center",
          width: "1%",
          dataType: "array",
          displayText: (e) => this.$options.filters.PersonShortName(e),
        },
        {
          text: "Статус",
          value: "Status",
          width: "1%",
          align: "center",

          dataType: "enum",
          options: CourseStatus,
        },
        {
          text: "Пройдено",
          value: "DoneEmployeesCount",
          width: "1%",
          align: "center",
          cellClass: " text-no-wrap",
          customSort: "NumWithPrecent",
          notGroup: true,
        },
      ],
      loading: true,
      dataSource: [],
      showCompleted: "notdone",
    };
  },

  async mounted() {
    this.init();
  },
  methods: {
    moment,
    PeriodFormat(DateIn, DateOut) {
      return Vue.filter("PeriodFormat")(DateIn, DateOut);
    },
    // подсчет процента сотрудников, прошедших учебный курс
    completionPercentage(item) {
      const completionPercentage =
        item?.AllEmployeesCount > 0
          ? parseFloat(
              (
                (item.DoneEmployeesCount / item.AllEmployeesCount) *
                100
              ).toFixed(2)
            )
          : 0;
      return completionPercentage;
    },
  },
};
</script>
